import Image from 'next/image';

import { Button, Text, TextKeyValuePair } from '@/atoms';
import {
  CART_ORDER_SOURCE,
  haflaRegistrationDetails,
  staticMediaStoreBaseURL
} from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';
import {
  downloadInvoicePDF,
  downloadQuotationPDF
} from '@/services/invoiceQuotationCommon.service';
import { getOrderInvoice } from '@/services/order.service';

const { companyName, formattedAddress, trnNumber } = haflaRegistrationDetails;

const downloadInvoice = async ({ orderId }) => {
  const { status, entity } = await getOrderInvoice({ orderId });
  status && downloadInvoicePDF({ entity });
};

const DownloadInvoicePDF = ({
  isMobile,
  orderId,
  showDownloadInvoicePDFButton
}) =>
  showDownloadInvoicePDFButton && (
    <Button
      {...{
        children: isMobile ? '' : 'Invoice',
        className: `font-medium rounded-full border border-white self-baseline py-1 ${
          isMobile ? 'w-7 h-7' : ''
        }`,
        iconGroupClassName: 'reduce-gap',
        iconHeight: 20,
        iconPosition: 'Left',
        iconUrl: `${staticMediaStoreBaseURL}/icons/download-white-icon.svg`,
        iconWidth: 20,
        onClick: () => downloadInvoice({ orderId }),
        width: `${isMobile ? '' : 'w-28'}`
      }}
    />
  );

const OpsUserAndAction = ({
  isMobile,
  opsUserName,
  orderId,
  showDownloadInvoicePDFButton
}) =>
  opsUserName && (
    <div className='hidden md:flex gap-2 justify-end mt-5 mr-2'>
      <div className='w-6 mt-1'>
        <Image
          alt='user'
          height={22}
          src={`${staticMediaStoreBaseURL}/icons/planner-icon.svg`}
          width={22}
        />
      </div>
      <Text
        {...{
          className: 'self-center text-lg font-medium whitespace-nowrap',
          content: opsUserName
        }}
      />
      <DownloadInvoicePDF
        {...{ isMobile, orderId, showDownloadInvoicePDFButton }}
      />
    </div>
  );

const EventCartOrderBanner = ({
  cartOrderBannerDetail,
  toggleToShowCartOrderDetailsBanner
}) => {
  const {
    cartId,
    cartOrderSource,
    headerLabel,
    opsUserName,
    orderId,
    referenceNumber,
    showDownloadPDFButton,
    userCartId
  } = cartOrderBannerDetail;
  const [isMobile] = useIsMobile();

  const isOrderSource = cartOrderSource === CART_ORDER_SOURCE.ORDER;

  const showDownloadQuotePDFButton = !isOrderSource && showDownloadPDFButton;
  const DownloadQuotePDF = () =>
    showDownloadQuotePDFButton && (
      <Button
        {...{
          children: `${isMobile ? '' : 'Quote'}`,
          className: `font-medium rounded-full border border-white self-baseline py-1 ${
            isMobile ? 'w-7 h-7' : ''
          }`,
          iconGroupClassName: 'reduce-gap',
          iconHeight: 20,
          iconPosition: 'Left',
          iconUrl: `${staticMediaStoreBaseURL}/icons/download-white-icon.svg`,
          iconWidth: 20,
          width: `${isMobile ? '' : 'w-28'}`,
          onClick: () =>
            downloadQuotationPDF({
              cartId,
              isStandardTypeSelected: true,
              userCartId
            })
        }}
      />
    );

  const showDownloadInvoicePDFButton = isOrderSource && showDownloadPDFButton;

  // TODO: Faizan - pass whole className string through props. - https://bitbucket.org/evinops-hafla/hafla/pull-requests/8183#comment-485242040

  return (
    <div
      className='cursor-pointer flex flex-col md:flex-row justify-between bg-border text-white rounded-t-lg p-4 md:p-6'
      onClick={() => toggleToShowCartOrderDetailsBanner()}
    >
      <div className='flex flex-col gap-1 md:gap-2'>
        <Text
          {...{
            className: 'text-base md:text-lg font-medium',
            content: companyName
          }}
        />
        <Text
          {...{
            content: formattedAddress,
            className: 'text-xs md:text-sm font-light max-w-80 truncate'
          }}
        />
        <Text
          {...{
            content: `TRN: ${trnNumber}`,
            className: 'text-sm font-light'
          }}
        />
      </div>
      {isOrderSource && (
        <div className='flex justify-between md:hidden'>
          <Text
            {...{
              content: referenceNumber,
              className: `flex text-xs md:text-base font-medium text-brand bg-white rounded-full py-1 px-3 md:px-4 mt-2 self-baseline `
            }}
          />
          <DownloadInvoicePDF
            {...{
              orderId,
              showDownloadInvoicePDFButton,
              isMobile
            }}
          />
        </div>
      )}
      <div className='flex flex-col mt-4 md:mt-0'>
        <div className='hidden md:block'>
          <TextKeyValuePair
            {...{
              className: 'flex-row justify-between md:justify-end',
              label: headerLabel.value,
              labelClass: `${headerLabel.class} text-sm md:text-xl self-center`,
              spaceTop: '',
              value: referenceNumber,
              valueClassName: `${headerLabel.valueClass} self-center text-xs md:text-base font-medium text-brand bg-white rounded-full py-1 px-3 md:px-4 ml-4`
            }}
          />
        </div>

        <div className='flex justify-end md:self-end gap-2'>
          <OpsUserAndAction
            {...{
              isMobile,
              opsUserName,
              orderId,
              showDownloadInvoicePDFButton
            }}
          />

          <div className='flex justify-between w-full mt-4 self-center items-end text-center'>
            <Text
              {...{
                content: headerLabel.value,
                className:
                  'uppercase font-semibold text-sm md:text-xl self-center block md:hidden'
              }}
            />
            {!isOrderSource && (
              <div className='flex gap-3'>
                <Text
                  {...{
                    content: referenceNumber,
                    className:
                      'flex md:hidden self-center text-xs md:text-base font-medium text-brand bg-white rounded-full py-1 px-3 md:px-4 ml-4'
                  }}
                />
                <DownloadQuotePDF />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCartOrderBanner;
