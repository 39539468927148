import Image from 'next/image';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import {
  Button,
  CloseIcon,
  InputField,
  Radio,
  Text,
  TextKeyValuePair,
  Uuid
} from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  amountFields,
  modeOfConfirmations,
  otherFields,
  paymentModes
} from '@/helpers/plannerCart/bookOrderPayment';
import { captureGTMEventProceedToPayment } from '@/lib/gtm';
import {
  formatToTwoDecimalString,
  parseFormatPriceValueFromAPI,
  parsePriceValueForAPI
} from '@/lib/numberStringUtils';
import { yupValidator } from '@/lib/yup-validator';

const BookOrderPaymentModalHeader = ({ cartNumber, userId }) => (
  <div className='flex justify-between pt-3 pb-5 text-base font-medium'>
    <TextKeyValuePair
      {...{
        label: 'Cart No:',
        labelClass: 'text-base',
        value: cartNumber,
        valueClassName: 'text-gray',
        className: 'flex-row'
      }}
    />
    <div className='flex'>
      <Uuid
        {...{
          label: 'User Id: ',
          value: userId,
          valueClass: 'text-gray px-1'
        }}
      />
    </div>
  </div>
);

const InfoMessage = ({ message, top, width }) =>
  message && (
    <div className='relative flex'>
      <div className='book-order-info cursor-pointer'>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/info-icon.svg`}
          width={16}
          height={16}
          alt='info'
        />

        <span
          className={`text-xs book-order-info-message font-light text-nero bg-error-tool-tip absolute ${width} p-3 rounded-xl z-10 left-10 ${top} tooltip`}
        >
          {message}
        </span>
      </div>
    </div>
  );

const SectionHeading = ({
  className,
  label,
  message,
  top = '-top-3',
  width = 'w-100'
}) => (
  <div className='flex gap-1 relative'>
    <Text
      {...{
        content: label,
        className: `text-base font-medium self-center ${className}`
      }}
    />
    <InfoMessage {...{ top, width, message }} />
  </div>
);

const ModeOfConfirmation = ({
  errors,
  getValues,
  handleModeOfCommunication,
  register
}) => (
  <Fragment>
    <SectionHeading
      {...{
        label: 'Mode of confirmation',
        message: `Mark Prepaid, If the customer has made any advance payment to.wards the order. Otherwise mark the appropriate channel where the customer agreed to pay`,
        top: '-top-6'
      }}
    />
    <div className='my-4'>
      <div className='flex gap-4'>
        {modeOfConfirmations.map(({ label, value }, index) => (
          <div
            className={`w-42.5 bg-lightgray h-11 rounded p-3 text-black shadow-card ${
              getValues('modeOfConfirmation') === value
                ? 'border border-brand'
                : ''
            }`}
            key={index}
            onClick={() => handleModeOfCommunication(value)}
          >
            <Radio
              {...{
                dbName: 'modeOfConfirmation',
                direction: 'right',
                errors,
                label,
                labelClass: 'text-sm font-medium',
                name: 'modeOfConfirmation',
                radioClass: 'radio-w16',
                register,
                value
              }}
            />
          </div>
        ))}
      </div>
      <div className='text-xs text-red-500 mt-1'>
        {errors?.modeOfConfirmation?.message}
      </div>
    </div>
  </Fragment>
);

const ModeOfPayment = ({
  getValues,
  handleModeOfPayment,
  register,
  errors
}) => (
  <Fragment>
    <SectionHeading
      {...{
        label: 'Mode of payment',
        className: 'mt-1',
        message: 'Mandatory if Mode of confirmation is Prepaid.'
      }}
    />
    <div className='my-4'>
      <div className='flex gap-4'>
        {paymentModes.map(({ icon, label, value }, index) => (
          <div
            key={index}
            className={`w-40 bg-lightgray text-black p-3 h-18 rounded shadow-card flex flex-col gap-2 ${
              getValues('paymentProviderType') === value
                ? 'border border-brand'
                : ''
            }`}
            onClick={() => handleModeOfPayment(value)}
          >
            <div className='flex justify-between'>
              <Image
                src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                width={28}
                height={28}
                alt='payment'
              />
              <Radio
                {...{
                  dbName: 'paymentProviderType',
                  direction: 'right',
                  errors,
                  labelClass: 'text-sm font-medium',
                  name: 'paymentProviderType',
                  radioClass: 'radio-w16',
                  register,
                  value
                }}
              />
            </div>
            <Text
              {...{
                content: label,
                className: 'text-sm font-medium'
              }}
            />
          </div>
        ))}
      </div>
      <div className='text-xs text-red-500 mt-1'>
        {errors?.paymentProviderType?.message}
      </div>
    </div>
  </Fragment>
);

const PaymentInfoRow = ({ errors, register, watch, handleAmountReceived }) => (
  <div className='flex gap-4'>
    {amountFields.map(
      ({ label, inputType, dbName, placeholder, disable }, key) => (
        <div
          className='w-1/3'
          key={key}
        >
          <SectionHeading
            {...{
              label,
              message:
                dbName === 'amountReceived' &&
                'Enter a positive real number without AED.'
            }}
          />
          <InputField
            {...{
              className: 'rounded-lg text-base',
              dbName,
              errors,
              disabled:
                disable || (watch && watch('modeOfConfirmation') !== 'PREPAID'),
              inputGroup: 'w-full mt-3',
              onChange: handleAmountReceived,
              placeholder,
              register: register(dbName),
              type: inputType
            }}
          />
        </div>
      )
    )}
  </div>
);

const PaymentRemarkRow = ({ register, errors }) => (
  <div className='flex gap-4 my-5'>
    {otherFields.map(
      ({ label, dbName, inputType, placeholder, infoMessage }, key) => (
        <div
          className={`flex flex-col ${
            dbName === 'reference' ? 'w-1/3' : 'w-2/3'
          }`}
          key={key}
        >
          <SectionHeading
            {...{
              label,
              errors,
              message: infoMessage,
              width: dbName === 'paymentRemarks' ? 'w-56' : 'w-100',
              top: dbName === 'paymentRemarks' ? '-top-8' : '-top-3'
            }}
          />
          <InputField
            {...{
              className: 'rounded-lg text-base',
              dbName,
              errors,
              inputGroup: 'w-full mt-3',
              placeholder,
              register: register(dbName),
              type: inputType
            }}
          />
        </div>
      )
    )}
  </div>
);

const BookOrderModal = ({
  cartDetails,
  onSubmitBookOrder,
  setShowBookOrderModal,
  setShowToast,
  showBookOrderModal
}) => {
  const {
    derivedValues: {
      orderTotal: orderTotalValue,
      promoCode: { code } = {}
    } = {},
    cartNumber,
    userCartId: userId,
    cartItems
  } = cartDetails;

  const yupResolver = yupValidator(
    yup.object().shape({
      modeOfConfirmation: yup
        .string()
        .typeError('Mode Of Confirmation is mandatory!')
        .required('Mode Of Confirmation is mandatory!'),
      paymentProviderType: yup
        .string()
        .when('modeOfConfirmation', {
          is: 'PREPAID',
          then: yup
            .string()
            .typeError(
              'Mode of payment is mandatory when prepaid confirmation!'
            )
            .required('Mode of payment is mandatory when prepaid confirmation!')
        })
        .nullable(),
      amountReceived: yup.number().when('modeOfConfirmation', {
        is: 'PREPAID',
        then: yup
          .number()
          .positive('Amount received should be > 0 when prepaid')
      }),
      paymentProviderId: yup.string().required('Reference # is mandatory!'),
      paymentRemarks: yup.string().when(['amountDue'], {
        is: (amountDue) => amountDue > 0,
        then: yup.string().required('Payment remarks is mandatory!')
      })
    })
  );

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      orderTotal: parseFormatPriceValueFromAPI(orderTotalValue) || '0.00',
      amountDue: parseFormatPriceValueFromAPI(orderTotalValue) || '0.00',
      amountReceived: '0.00'
    }
  });

  const getAmountDue = (amountReceived) =>
    formatToTwoDecimalString({
      value: getValues('orderTotal') - amountReceived,
      convertValueToUpperDenomination: false,
      trimZeroFraction: false
    });

  const handleAmountReceived = (data) => {
    setValue('amountDue', getAmountDue(data.target.value || 0));
  };

  const handleModeOfCommunication = (value) => {
    setValue('modeOfConfirmation', value);
    setValue('amountReceived', 0);
    setValue('amountDue', getValues('orderTotal') || 0);
  };

  const handleModeOfPayment = (value) => {
    setValue('paymentProviderType', value, { shouldValidate: true });
  };

  const onSubmit = async ({
    modeOfConfirmation,
    paymentProviderType,
    amountDue: paymentDue,
    amountReceived: transactionAmount,
    orderTotal,
    paymentRemarks,
    paymentProviderId
  }) => {
    const { status, message } = await onSubmitBookOrder({
      modeOfConfirmation,
      orderTotal: parsePriceValueForAPI(orderTotal),
      paymentDue: parsePriceValueForAPI(paymentDue),
      paymentProviderType,
      transactionAmount: parsePriceValueForAPI(transactionAmount),
      paymentRemarks,
      paymentProviderId
    });
    captureGTMEventProceedToPayment({
      cartItems,
      code,
      orderTotal,
      paymentType: paymentProviderType
    });
    if (!status) {
      setShowToast({ show: true, message, status });
    }
  };

  const toggleBookOrderModalView = () =>
    setShowBookOrderModal(!showBookOrderModal);

  return (
    <Modal
      isOpen={showBookOrderModal}
      toggle={toggleBookOrderModalView}
    >
      <ModalBody
        className='z-120 bg-white m-18 w-full md:w-56.25
      min-h-96 mx-auto px-8 md:rounded-lg pb-2 pt-4'
      >
        <div className='flex-1 flex flex-row-reverse relative left-4'>
          <CloseIcon {...{ onClick: toggleBookOrderModalView }} />
        </div>
        <Text
          {...{
            className: 'text-center text-xl font-medium pb-5',
            content: 'Book Order - Payment Details'
          }}
        />
        <BookOrderPaymentModalHeader {...{ cartNumber, userId, errors }} />
        <div className='flex flex-col text-xl'>
          <ModeOfConfirmation
            {...{
              register,
              handleModeOfCommunication,
              errors,
              getValues
            }}
          />
          <ModeOfPayment
            {...{ getValues, handleModeOfPayment, register, errors }}
          />
          <PaymentInfoRow
            {...{ register, watch, handleAmountReceived, errors }}
          />
          <PaymentRemarkRow {...{ register, errors }} />
        </div>
        <Button
          {...{
            width: 'w-40',
            className: `py-3 px-2 text-sm font-medium mx-auto my-4 rounded-lg bg-brand-gradient text-white`,
            children: 'Confirm Order',
            onClick: handleSubmit(onSubmit)
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default BookOrderModal;
