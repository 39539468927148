import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

import { Text, TextKeyValuePair } from '@/atoms';
import {
  CART_LIST_TYPE,
  CART_STATUS,
  staticMediaStoreBaseURL
} from '@/config/common';
import copyToClipBoard from '@/helpers/copyToClipBoard';
import { convertToStandardDateFormat } from '@/lib/time';
import { getEditOrderURL } from '@/services/opsPortal.service';
import {
  PAYMENT_SOURCE_TYPE,
  generatePaymentLink
} from '@/services/payment.service';

const CartInnerBody = ({
  cartCreatedBy,
  createdAt,
  isOrdered,
  orderNumber = ''
}) => {
  const cartKeyValuePairs = [
    { label: `${isOrdered ? 'Cart' : ''} Created By:`, value: cartCreatedBy },
    {
      label: `${isOrdered ? 'Cart' : ''} Created At:`,
      value: convertToStandardDateFormat(createdAt)
    },
    { label: 'Order No.:', value: orderNumber, visible: isOrdered }
  ];
  return (
    <div className='flex  flex-wrap  gap-10 flex-row gap-y-4 pt-2 md:pt-5 text-sm'>
      {cartKeyValuePairs.map(
        ({ label, value, visible = true }, index) =>
          visible && (
            <TextKeyValuePair
              key={index}
              {...{ label, value }}
            />
          )
      )}
    </div>
  );
};

const handlePaymentLinkClick = async ({
  setShowToast,
  userId,
  id,
  paymentSourceType
}) => {
  const isClipboardAvailable = Boolean(navigator.clipboard);
  if (isClipboardAvailable) {
    await copyToClipBoard(
      generatePaymentLink({
        paymentSourceType,
        referenceId: id,
        userId
      })
    );
  }
  setShowToast({
    show: true,
    status: isClipboardAvailable,
    message: isClipboardAvailable
      ? 'Successfully generated and copied payment link'
      : 'Failed to copy to clipboard'
  });
};

const EventCartCardBodyCTA = ({
  authUser,
  id,
  isUserPlanner,
  onClickCopyQuoteLink,
  setShowToast,
  status
}) => {
  const showLinks = isUserPlanner && ['Collaborate'].includes(status);
  const userId = authUser?.id;
  return (
    showLinks && (
      <div className='flex gap-3 mt-4 md:mt-0 md:self-end'>
        <CTAWithIcon
          {...{
            alt: 'Copy Payment Link',
            icon: 'copy-red.svg',
            id: 'paymentLink',
            label: 'Payment Link',
            onClick: (event) => {
              event.stopPropagation();
              handlePaymentLinkClick({
                setShowToast,
                userId,
                id,
                paymentSourceType: PAYMENT_SOURCE_TYPE.CART
              });
            }
          }}
        />

        <Fragment>
          <CTAWithIcon
            {...{
              alt: 'Copy Web Quote Link',
              icon: 'copy-red.svg',
              id: 'customerLink',
              label: 'Web Quote Link',
              onClick: onClickCopyQuoteLink
            }}
          />
        </Fragment>
      </div>
    )
  );
};

const OrderedCartCardBodyCTA = ({
  authUser,
  hidePaymentLinkForOrderCart,
  isUserPlanner,
  orderId,
  setShowToast
}) =>
  isUserPlanner && (
    <div className='flex gap-3 mt-4 md:mt-0 md:self-end'>
      {!hidePaymentLinkForOrderCart && (
        <CTAWithIcon
          {...{
            alt: 'paymentLink copy',
            height: 24,
            icon: 'copy-with-frame.svg',
            id: 'paymentLink',
            label: 'Payment Link',
            width: 24,
            onClick: (event) => {
              event.stopPropagation();
              handlePaymentLinkClick({
                setShowToast,
                userId: authUser?.id,
                id: orderId,
                paymentSourceType: PAYMENT_SOURCE_TYPE.ORDER
              });
            }
          }}
        />
      )}

      <div>
        <Link href='#'>
          <a
            href={getEditOrderURL({ orderId })}
            target='_blank'
            rel='noreferrer'
            onClick={(e) => e.stopPropagation()}
            className='flex gap-1 bg-gradient-to-r from-light-orange via-dark-orange text-white text-sm font-medium py-1 rounded-lg px-4'
          >
            <div className='flex w-6 h-6 self-center'>
              <Image
                {...{
                  alt: 'manage order',
                  height: 20,
                  src: `${staticMediaStoreBaseURL}/icons/manage-order.svg`,
                  width: 20
                }}
              />
            </div>
            <Text {...{ content: 'Manage Order', className: 'self-center' }} />
          </a>
        </Link>
      </div>
    </div>
  );

const CTAWithIcon = ({ alt, height, icon, id, label, onClick, width }) => (
  <Text
    id={id}
    content={
      <div
        className='flex gap-1 cursor-pointer leading-6 text-brand border border-brand rounded-lg px-3 md:px-4 py-1'
        onClick={onClick}
      >
        <span className='flex self-center w-6 h-6'>
          <Image
            {...{
              src: `${staticMediaStoreBaseURL}/icons/${icon}`,
              width: width || 16,
              height: height || 16,
              alt
            }}
          />
        </span>
        <div className='flex-1'>{label}</div>
      </div>
    }
  />
);

const CartCardBodyCTAType = {
  [CART_LIST_TYPE.EVENT]: EventCartCardBodyCTA,
  [CART_LIST_TYPE.ORDER]: OrderedCartCardBodyCTA,
  [CART_LIST_TYPE.ARCHIVE]: EventCartCardBodyCTA
};

const CartCardBody = ({
  authUser,
  cartCreatedBy,
  cartOrder,
  cartListType,
  createdAt,
  id,
  isUserPlanner,
  onClickCopyQuoteLink,
  setShowToast,
  status,
  setShowPDFDownloadConfirmationModal
}) => {
  const CartCardBodyCTA = CartCardBodyCTAType[cartListType];
  const isOrdered = status === CART_STATUS.ORDER;
  return (
    <div className='flex flex-col md:flex-row justify-between'>
      <CartInnerBody
        {...{
          cartCreatedBy,
          createdAt,
          isOrdered,
          orderNumber: cartOrder?.order.orderNumber
        }}
      />
      <CartCardBodyCTA
        {...{
          authUser,
          hidePaymentLinkForOrderCart: true,
          id,
          isUserPlanner,
          onClickCopyQuoteLink,
          orderId: cartOrder?.order.id,
          setShowPDFDownloadConfirmationModal,
          setShowToast,
          status
        }}
      />
    </div>
  );
};

export default CartCardBody;
