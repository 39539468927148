import { jsPDF as JsPDF } from 'jspdf';
import 'jspdf-autotable';

import { INVOICE_QUOTE_PDF_TYPE } from '@/config/common';
import haflaLogo from '@/config/haflaLogoBase64.json';
import { jsPDFOptions, pdfConstants } from '@/config/quoteAndInvoice';
import {
  customRenderingOfOrderItemRowCell,
  getPDFTemplateData
} from '@/helpers/invoiceQuotationCommon';
import * as HttpService from '@/services/http.service';
import { GET_CART_QUOTATION } from '@/services/url.service';

export const getQuotePDFDetails = ({ cartId, userCartId }) =>
  HttpService.getWithoutAuth(GET_CART_QUOTATION({ cartId, userCartId }));

const getPdfFileName = (documentIdentifier) =>
  `Hafla_Quotation_${documentIdentifier}.pdf`;

const getJsPDFDocument = (pdfData, entity) => {
  const DEFAULT_HEAD_STYLE = {
    fillColor: pdfConstants.tableHeaderBackgroundColor,
    textColor: pdfConstants.blackColor
  };

  const parseAndStyleUserProfileJsAutotableCell = ({ row, column, cell }) => {
    const isCellRowHeaderOfBody = () =>
      row.section === 'body' && (column.index === 0 || column.index === 2);
    const isCustomerAddressRow = row.cells[0].raw === 'Delivery Address';
    const isCustomerEmailRow = row.cells[0].raw === 'Email ID';
    const isQuoteInvoiceForRow = row.section === 'head';
    if (isCellRowHeaderOfBody()) {
      Object.assign(cell, {
        ...cell,
        styles: {
          ...cell.styles,
          fillColor: pdfConstants.greyColor
        }
      });
    }
    if (isCustomerAddressRow || isCustomerEmailRow || isQuoteInvoiceForRow) {
      // eslint-disable-next-line no-param-reassign
      row.cells[1].colSpan = 3;
    }
  };

  const getXCordinateForTitle = (title) => {
    const defaultX = 160;
    let offset = 0;
    switch (title) {
      case 'PROFORMA INVOICE':
        offset = 9;
        break;
      case 'TAX INVOICE':
        offset = 3;
        break;
      case 'QUOTATION':
        break;
      default:
        break;
    }
    return defaultX - offset;
  };

  const getUserProfileAutoTable = (
    finalYY,
    { userProfileHead, userProfileBody },
    { blackColor }
  ) => ({
    margin: { left: jsPDFOptions.startX },
    startY: finalYY + 15.5,
    theme: 'grid',
    styles: {
      textColor: blackColor,
      weight: '900',
      fontStyle: 'normal',
      fontWeight: '900'
    },
    columnStyles: {
      1: { cellWidth: 55 }
    },
    headStyles: DEFAULT_HEAD_STYLE,
    head: userProfileHead,
    body: userProfileBody,

    didParseCell: parseAndStyleUserProfileJsAutotableCell
  });
  const parseAndStyleOrderItemJsAutotableCell = ({ row, column, cell }) => {
    const [col1, col2] = row.raw;
    const isPriceNoteCell = column.index === 1 && col1 === ' ' && col2 !== '';

    let styleOverride = {};
    if (isPriceNoteCell) {
      styleOverride = { cellPadding: 1, fontSize: 7 };
    }
    Object.assign(cell, {
      ...cell,
      styles: {
        ...cell.styles,
        ...styleOverride
      }
    });
  };

  const getOrderItemsAutoTable = (
    finalYY,
    { orderItemsHead, orderItemsBody }
  ) => ({
    startY: finalYY,
    margin: { left: jsPDFOptions.startX },
    theme: 'plain',
    columnStyles: {
      0: { cellWidth: 8 },
      2: { cellWidth: 20 },
      3: { cellWidth: 20 },
      4: { cellWidth: 20 }
    },
    headStyles: {
      ...DEFAULT_HEAD_STYLE,
      fontStyle: 'normal',
      fontSize: 10
    },
    bodyStyles: { lineColor: [0, 0, 0] },
    head: orderItemsHead,
    body: orderItemsBody,
    didParseCell: parseAndStyleOrderItemJsAutotableCell,
    willDrawCell: customRenderingOfOrderItemRowCell
  });
  const parseAndStyleOrderSummaryJsAutotableCell = ({ cell }) => {
    let styleOverride = {};
    if (cell.raw !== '') {
      styleOverride = { ...styleOverride, lineWidth: 0.1 };
    }
    Object.assign(cell, {
      ...cell,
      styles: {
        ...cell.styles,
        ...styleOverride
      }
    });
  };
  const getOrderSummaryAutoTable = (finalYY, { orderSummaryBody }) => ({
    startY: finalYY,
    margin: { left: jsPDFOptions.startX },
    theme: 'plain',
    columnStyles: {
      0: { cellWidth: 8 },
      2: { cellWidth: 20 },
      3: { cellWidth: 40 },
      4: { cellWidth: 20 }
    },
    bodyStyles: { lineColor: [0, 0, 0] },
    body: orderSummaryBody,
    didParseCell: parseAndStyleOrderSummaryJsAutotableCell
  });

  const getAdditionalNotesAutoTable = (
    finalYY,
    { additionalNotesHead, additionalNotesBody }
  ) => ({
    startY: finalYY,
    margin: { left: jsPDFOptions.startX },
    theme: 'plain',
    headStyles: {
      ...DEFAULT_HEAD_STYLE,
      fontStyle: 'normal'
    },
    bodyStyle: {
      fontSize: 8
    },
    head: additionalNotesHead,
    body: additionalNotesBody
  });

  const getPaymentTnCAutoTable = (
    finalYY,
    { paymentTermHead, paymentTermsBody }
  ) => ({
    startY: finalYY,
    theme: 'plain',
    margin: { left: jsPDFOptions.startX },
    headStyles: {
      ...DEFAULT_HEAD_STYLE,
      fontStyle: 'normal'
    },
    columnStyles: {
      0: { cellWidth: 40 },
      1: { cellWidth: 20 }
    },
    head: paymentTermHead,
    body: paymentTermsBody
  });

  const getGeneralTncAutoTable = (
    finalYY,
    { generalTncHead, generalTncBody }
  ) => ({
    startY: finalYY,
    theme: 'plain',
    margin: { left: jsPDFOptions.startX },
    headStyles: {
      ...DEFAULT_HEAD_STYLE,
      fontStyle: 'normal'
    },
    head: generalTncHead,
    body: generalTncBody
  });

  const getPaymentDetailAutoTable = (
    finalY,
    { paymentDetailHead, paymentDetailBody: body }
  ) => ({
    startY: finalY,
    theme: 'plain',
    margin: { left: jsPDFOptions.startX },
    bodyStyles: {
      cellPadding: 0.5
    },
    alternateRowStyles: {
      3: { halign: 'right' },
      4: { halign: 'right' },
      5: { halign: 'right' },
      6: { halign: 'right' }
    },
    headStyles: {
      ...DEFAULT_HEAD_STYLE,
      fontStyle: 'normal'
    },
    head: paymentDetailHead,
    body
  });

  const doc = new JsPDF(
    jsPDFOptions.PDF_ORIENTATION,
    null,
    jsPDFOptions.PAPER_SIZE
  );
  const addAutoGeneratedDeclaration = (finalYY) => {
    doc.setFontSize(8);
    doc.setFont(undefined, 'normal');
    doc.text(
      jsPDFOptions.startX,
      finalYY,
      '*This is a computer-generated document, No signature is required.'
    );
  };

  const addFooter = () => {
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i += 1) {
      doc.setPage(i);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(10, 284, 200, 284);
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      doc.setFont(undefined, 'normal');
      if (i === pageCount) {
        addAutoGeneratedDeclaration(283);
      }
      doc.setFontSize(10);
      doc.text(
        10,
        288,
        'If you have any questions about this quote, please contact @'
      );
      doc.setFont(undefined, 'bold');
      doc.textWithLink(`${pdfConstants.haflaMobileNumber} | `, 106, 288, {
        url: `tel:${pdfConstants.haflaMobileNumber}`
      });
      doc.textWithLink(`${pdfConstants.haflaEmail} | `, 135, 288, {
        url: `mailto:${pdfConstants.haflaEmail}`
      });
      doc.setTextColor(138, 180, 248);
      doc.textWithLink(`${pdfConstants.haflaWebsiteText}`, 174, 288, {
        url: `${pdfConstants.haflaWebsiteUrl}`
      });
    }
  };

  doc.setTextColor(0, 0, 0);
  doc.setFont(undefined, 'bold');
  doc.text(getXCordinateForTitle(pdfData.title), 20, pdfData.title);
  doc.setFontSize(22);

  doc.addImage(haflaLogo.haflaLogoBase64, 'PNG', 167, 22, 23, 23);
  doc.setFontSize(10);

  doc.text(jsPDFOptions.startX, 19, `${pdfConstants.companyName}`);
  doc.autoTable({
    startX: jsPDFOptions.startX,
    startY: 19.9,
    tableWidth: 60,
    columnStyles: {
      0: { cellWidth: 150 }
    },
    theme: 'plain',
    body: pdfConstants.address
  });

  doc.setFont(undefined, 'bold');
  doc.text(jsPDFOptions.startX, 38, 'TRN: ');
  doc.text(26, 38, `: ${pdfConstants.TRN}`);
  doc.setFontSize(14);

  let { finalY } = doc.lastAutoTable;
  doc
    .text(
      jsPDFOptions.startX,
      finalY + 14,
      `${entity?.logisticsSummary?.eventName}`
    )
    .setFont(undefined, 'bold');

  doc.autoTable({
    margin: { left: 160 },
    startY: finalY + 8,
    bodyStyles: {
      halign: 'center',
      fontStyle: 'bold',
      fontSize: 14
    },
    theme: 'plain',
    body: [[`AED ${entity?.orderSummary?.orderTotal} `]]
  });

  doc.autoTable(getUserProfileAutoTable(finalY, pdfData, pdfConstants));

  finalY = doc.lastAutoTable.finalY;
  doc.autoTable(getOrderItemsAutoTable(finalY, pdfData, pdfConstants));
  finalY = doc.lastAutoTable.finalY;
  doc.autoTable(getOrderSummaryAutoTable(finalY, pdfData, pdfConstants));

  finalY = doc.lastAutoTable.finalY;
  doc.autoTable(getAdditionalNotesAutoTable(finalY, pdfData, pdfConstants));

  finalY = doc.lastAutoTable.finalY;
  doc.autoTable(getPaymentTnCAutoTable(finalY, pdfData, pdfConstants));

  finalY = doc.lastAutoTable.finalY;
  doc.autoTable(getPaymentDetailAutoTable(finalY, pdfData, pdfConstants));
  doc.autoTable();

  finalY = doc.lastAutoTable.finalY;
  doc.autoTable(getGeneralTncAutoTable(finalY, pdfData, pdfConstants));
  finalY = doc.lastAutoTable.finalY + 2;

  addFooter();
  return doc;
};

const createAndDownloadPDF = ({
  entity = {},
  invoiceQuotePDFType = INVOICE_QUOTE_PDF_TYPE.QUOTE,
  isProforma = false
}) => {
  const pdfData = getPDFTemplateData({
    entity,
    invoiceQuotePDFType,
    isProforma
  });

  const jsPDFDocument = getJsPDFDocument(pdfData, entity);

  jsPDFDocument.save(getPdfFileName(entity.documentId));
};

export const downloadQuotationPDF = async ({
  cartId,
  isProforma,
  isStandardTypeSelected,
  userCartId
}) => {
  const {
    entity: {
      documentId,
      lineItems,
      logisticsSummary,
      orderSummary,
      paymentConfirmationDetails,
      paymentTerms
    }
  } = await getQuotePDFDetails({ cartId, userCartId });

  createAndDownloadPDF({
    entity: {
      documentId,
      isStandardTypeSelected,
      lineItems,
      logisticsSummary,
      orderSummary,
      paymentConfirmationDetails,
      paymentTerms
    },
    invoiceQuotePDFType: INVOICE_QUOTE_PDF_TYPE.QUOTE,
    isProforma
  });
};

export const downloadInvoicePDF = ({ entity, isProforma }) => {
  createAndDownloadPDF({
    entity,
    invoiceQuotePDFType: INVOICE_QUOTE_PDF_TYPE.INVOICE,
    isProforma
  });
};
