import { Text } from '@/atoms';
import { PRODUCT_TYPE } from '@/config/common';
import {
  parseFormatPercentValueFromAPI,
  parseFormatPriceValueFromAPI
} from 'lib/numberStringUtils';

const getRackPriceString = ({
  rackDiscountPercentage,
  type: productType,
  unitListedPrice
}) => {
  if (productType === PRODUCT_TYPE.QUOTE) {
    return 0;
  }

  const isDiscountAvailable = Boolean(rackDiscountPercentage);

  const priceAfterDiscount = isDiscountAvailable
    ? unitListedPrice -
      (unitListedPrice * (rackDiscountPercentage || 0)) / 10000
    : unitListedPrice;

  const prefix = `${isDiscountAvailable ? 'AED ' : ''}`;
  const value = parseFormatPriceValueFromAPI(Number(priceAfterDiscount || 0));

  return `${prefix}${value}`;
};

const DiscountPercentWithPriceStrike = ({ discountPercent, unitRackPrice }) => (
  <div className='flex gap-2'>
    <Text
      {...{
        content: `AED ${unitRackPrice}`,
        className: 'line-through text-xs text-dim-gray self-center'
      }}
    />
    <Text
      {...{
        content: `${discountPercent}% off`,
        className: 'text-green text-md font-medium'
      }}
    />
  </div>
);

const RackRateOfOrderedCartItem = ({
  rackDiscountPercentage,
  type: productType,
  unitListedDiscount,
  unitListedPrice,
  unitRackPrice
}) => {
  const rackPriceString = getRackPriceString({
    rackDiscountPercentage,
    type: productType,
    unitListedPrice
  });

  const showDiscountPercentWithPriceStrike =
    productType === PRODUCT_TYPE.CART && Boolean(unitListedDiscount);

  return (
    <>
      <Text {...{ content: rackPriceString }} />
      {showDiscountPercentWithPriceStrike && (
        <DiscountPercentWithPriceStrike
          {...{
            discountPercent: parseFormatPercentValueFromAPI(
              rackDiscountPercentage || 0
            ),
            unitRackPrice: parseFormatPriceValueFromAPI(unitRackPrice)
          }}
        />
      )}
    </>
  );
};

export default RackRateOfOrderedCartItem;
